<template>
    <div class="footer">
        <div class="ft_top" v-if="name != 'user'">
            <ul class="public_width d-around">
                <li>
                    <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/03/%E9%A3%9E%E4%B9%A620211109-152711.png" alt="云方案" />
                    <div>
                        <div class="title">种类丰富</div>
                        <p>丰富的服务种类，百纳芯城产品种类丰富，</p>
                        <p>多方合作，资源海量，质量保障，</p>
                        <p>用户可放心选购！</p>
                    </div>
                </li>
                <li>
                    <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/03/%E9%A3%9E%E4%B9%A620211109-152714.png" alt="云方案" />
                    <div>
                        <div class="title">优质服务</div>
                        <p>优质的服务项目，百纳芯城专注工程师一站式服务，</p>
                        <p>项目多多， 活动多多，</p>
                        <p>精彩等您参与！</p>
                    </div>
                </li>
                <li>
                    <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/03/%E9%A3%9E%E4%B9%A620211109-152717.png" alt="云方案" />
                    <div>
                        <div class="title">专业团队</div>
                        <p>专业的服务团队，百纳芯城一对一服务，</p>
                        <p>加强与用户的沟通交流，</p>
                        <p>使其有宾至如归的感受！</p>
                    </div>
                </li>

            </ul>
        </div>

        <div class="ft_link">
            <div class="public_width ft_link_wrap">
                <div class="ft_out">
                    <div class="ft_friend">
                        <span>友情链接：</span>
                        <a href="https://www.ebaina.com" target="_blank" rel="nofollow">易百纳技术社区</a>
                        <a href="http://www.apnring.com" target="_blank" rel="nofollow">艾伯瑞电子</a>
                        <a href="https://www.anxtech.net" target="_blank" rel="nofollow">艾诺芯智能</a>
                        <a href="https://www.ebaina.com/channels/13" target="_blank" rel="nofollow">海思论坛</a>
                        <a href="https://www.ebaina.com/channels/16" target="_blank" rel="nofollow">瑞芯微论坛</a>
                        <a href="https://www.ebaina.com/channels/22" target="_blank" rel="nofollow">爱芯元智</a>
                        <!-- <a href="https://www.wamuju.com/#/index" target="_blank" rel="nofollow">挖模具</a> -->
                        <!-- <a href="https://b.ebaina.com/#/smt" target="_blank" rel="nofollow">SMT加工</a> -->
                    </div>
                    <div>
                        <span>其他链接：</span>
                        <p @click="to_help(1)">使用帮助</p>
                        <p @click="to_help(5)">使用协议</p>
                        <p @click="to_help(6)">免责声明</p>
                        <!-- <p @click="to_help(4)">关于我们</p> -->
                    </div>
                </div>
                <div class="ft_code">
                    <div class="ft_yun">
                        <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-175013.png" alt="云方案" />
                        <p>扫描关注公众号</p>
                    </div>

                    <div class="ft_yun">
                        <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202110/16/%E5%9B%BE%E7%89%87/%E7%BB%84%201104.png" alt="云方案" />
                        <p>扫描下载云方案</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="ft_bt">Copyright &copy; 2009-2021 南京启诺信息技术有限公司版权所有.&nbsp;All Rights Reserved.&nbsp;苏ICP备14036084号-1</div>
    </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
        props: {
            active: String,
        },
        setup(props,ctx) {
            let name = reactive(props.active);
            const router = useRouter();

            let to_help = (v) => {
                router.push({
                    path: '/help',
                    query:{
                        id: v
                    }
                })

                if(name == 'help') {
                    ctx.emit('get',{id: v});
                }

                window.scrollTo(100,0)
            }
            
            return {
                name,
                to_help
            };
        },
    });
</script>

<style lang="less">
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .ft_top {
        padding: 40px 0 50px;
        background: #fff;
        li {
            display: flex;
            align-items: center;
            text-align:left;
            margin-right: 100px;
            &:last-child {
                margin-right: 0;
            }
        }

        img {
            height: 57px;
            width: 57px;
            margin-right: 25px;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #333;
            margin-bottom: 6px;
        }
        
        p {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #666;
        }
    }

    .ft_link {
        background: #333;
    }

    .ft_link_wrap {
        display: flex;
        justify-content: space-between;
        height: 210px;
        padding: 50px 0 22px;
    }

    .ft_out {
        margin-top:10px;
        font-size: 14px;
        color: #fff;
        text-align: left;

        span {
            margin-right: 50px;
            color: #999;
        }

        a,p {
            font-size: 14px;
            line-height: 20px;
            margin-right: 50px;
            display: inline-block;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ft_friend {
        margin-bottom: 30px;

        a {
            
            text-decoration: underline!important;
        }
    }

    .ft_code {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .ft_yun{
        margin-left: 30px;
        img {
            height: 110px;
            width: 110px;
            border-radius: 10px;
            background: #fff;
            margin-bottom: 12px;
        }
        p{
            line-height: 30px;
            font-size: 13px;
            color: #EDEDED;
            text-align: center;
        }
    }

    .ft_bt {
        color: #999;
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        background: #0F0F0F;
        text-align: center;
    }
}
</style>
