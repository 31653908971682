<template>
    <div class="nav">
        <div class="nav_tip">
            <div class="public_width d-between">
                <p class="tip_to" @click="to_ebn()"><i class="iconfont icon-icon-z"></i>前往<span>易百纳技术社区</span></p>
                <div class='tip_serve'>
                    <span>服务时间：8:30-18:30</span>
                    <span>联系客服：qq-1289459644</span>
                </div>
            </div>
        </div>
        <div class="nav_other">
            <div class="public_width nav_wrap d-center">
                <!-- logo -->
                <div class="nav_logo d-center" @click="to_home()">
                    <img class="nav_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/production/direct/resource/202309/14/logo.png" alt="LOGO"/>
                </div>
                <!-- 搜索框 -->
                <div class="search">
                    <div class="nav_search d-center">
                        <div class="search_box d-center">
                            <el-input class="search_val" placeholder="请输入内容" v-model="search" :prefix-icon="Search"  @keyup.enter="to_search()" @focus="focus_search()"/>
                            <el-button class="search_btn" @click="to_search()">搜索</el-button>
                        </div>
                        <div class="result" v-show="result_show">
                            <div class="result_title">历史搜索</div>
                            <ul>
                                <li class="result_item d-between" v-for="(v,k) in result" :key="k" v-show="k<7" @click.stop="history_go(v)">{{v}}  <i class="del iconfont icon-xx" @click="result_del(v,k)"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div class="search_word d-flex" v-if="hot.length > 0">
                        热搜关键词：
                        <span v-for="(item,index) in hot" :key="item.id" @click="hot_search(item.name)" v-show="index<5">{{item.name}}</span>
                    </div>
                </div> 

                <!-- 登录 -->
                <div class="is_login_box d-flex" v-if="avatar">
                    <div class="item d-center" @click="to_msg()">
                        <div class="bage">
                            <i class="t-icon t-icon-a-zu11231"></i>
                            <div class="dot" v-if="msg_count">{{msg_count}}</div>
                        </div><span class="txt">消息</span>
                    </div>
                    <div class="item d-center" @click="to_car()">
                        <div class="bage">
                            <i class="t-icon t-icon-a-zu11191"></i>
                            <div class="dot" v-if="car_count">{{car_count}}</div>
                        </div><span class="txt">购物车</span>
                    </div>
                    <div class="d-center" @click="to_user()">
                        <img :src="avatar" alt=""/>
                        <div class="nickname">{{ userName }}</div>
                    </div>
                    <div class="logout" @click="logout()">退出</div>
                </div>
                <div class="logo_txt d-flex" v-else @click="go_login()"><i class="t-icon t-icon-e-tx"></i>登录/注册</div>
            </div>
        </div>
        <el-dialog
            custom-class="modal_login"
            v-model="show"
            :before-close="loginClose"
            >
            <div class="login d-center">
                <!-- 二维码 -->
                <div class="login_box" v-if="erweima_show">
                    <div class="title">快速登录</div>
                    <img class="qrcode" :src="qrcode" alt="登录二维码" />
                    <p class="tip">扫码关注微信公众号，即可登录</p>
                    <el-divider content-position="center"><p class="divi">其他登录方式</p></el-divider>
                    <div class="qq d-center" @click="login_qq()"><i class="t-icon t-icon-qq"></i></div>
                    <div class="agree">注册登录即表示同意<span @click="to_agreement()">《使用协议》</span></div>
                </div>

                <!-- 手机号 -->
                <div class="login_box phone_box" v-if="phone_show">
                    <div class="title">欢迎您，请绑定手机号</div>

                    <el-form :model="form" :rules="rules" ref="form_login" label-width="0" class="demo-ruleForm">
                        <!-- 昵称 -->
                        <el-form-item prop="nickname">
                            <el-input type="text" v-model="form.nickname" autocomplete="off" hide-required-asterisk placeholder="请输入昵称" prefix-icon="el-icon-user"></el-input>
                        </el-form-item>

                        <!-- 手机号 -->
                        <el-form-item prop="phone">
                        <el-input type="text" v-model="form.phone" autocomplete="off" hide-required-asterisk placeholder="请输入11位手机号" maxlength="11" prefix-icon="el-icon-phone"></el-input>
                        </el-form-item>

                        <!-- 验证码 -->
                        <el-form-item prop="q_code">
                        <el-input
                            type="text"
                            v-model="form.q_code"
                            placeholder="请输入验证码"
                            autocomplete="off"
                            hide-required-asterisk
                            maxlength="6"
                            prefix-icon="el-icon-message"
                        >
                        </el-input>

                        <el-button
                            type="text"
                            class="get_q_code"
                            :disabled="timeout"
                            @click="get_code"
                        >
                            {{ q_code_msg }}
                        </el-button>
                        </el-form-item>

                        <el-form-item>
                        <el-button type="primary" class="submit_btn" @click="submitForm"
                            >注册</el-button
                        >
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>

</template>
<script>
import { getData,postData } from "@/api/user";
import Cookies from "js-cookie";
import { ElMessage } from "element-plus";

import {toRefs, reactive, computed, defineComponent,watch,ref,onMounted, nextTick } from 'vue'
import { useStore } from "vuex"; 
import { useRoute, useRouter} from "vue-router";

export default defineComponent({
    props: {
        active: String,
    },
    emits:['get','help'],
    setup(props,ctx) {
        const store = useStore()
        const route = useRoute()
        const router =  useRouter()
        const form_login = ref(null)
        const name = reactive(props.active)

        let isCellPhone = (val) => {
            if (!/^1[3456789]\d{9}$/.test(val)) {
                return false;
            } else {
                return true;
            }
        }

        let checkphone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("手机号不能为空"));
            } else if (!isCellPhone(value)) {
                //引入methods中封装的检查手机格式的方法
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        }

        let q_code = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("验证码不能为空"));
            } else if (value.length != 6) {
                //引入methods中封装的检查手机格式的方法
                callback(new Error("验证码为6位数字"));
            } else {
                callback();
            }
        }

        let state = reactive ({
            ali_oss: store.state.ali_oss,
            userid: store.state.userId,
            channel_id: 0,
            ebaina_url:store.state.ebaina_url,


            cate: route.query.cate || '',

            // 搜索和个人中心
            search: '',
            hot:[],
            
            // 登录弹窗
            qqwindow: "",
            qrcode: "",
            erweima_show: true,
            phone_show: false,
            form: {
                nickname: "",
                phone: "",
                q_code: "",
                openid: "",
                provider: "",
                key: "",
                register_key: "",
            },
            rules: {
                nickname: [
                    { required: true, message: "昵称不能为空", trigger: "blur" },
                ],
                phone: [{ required: true, validator: checkphone, trigger: "blur" }],
                q_code: [{ required: true, validator: q_code, trigger: "change" }],
            },
            q_code_msg: "发送验证码",
            timeout: false,
            time_Interval: "",
            inter_login: "",
            login: "/", // 登录跳转的路径
            result: [],
            result_show: false
        })

        let show = computed(() => { 
            return store.state.login_box;
        })

        let avatar = computed(() => { 
            return store.state.avatarImgPath;
        })

        let userName = computed(() => { 
            return store.state.userName;
        })

        let msg_count = computed(()=>{
            return store.state.message_count
        })
        let car_count = computed(()=>{
            return store.state.shopping_car_count

        })

        watch(show,(newValue) => {
            if(newValue) {
                getData({
                    url: "api/login/qrcode",
                    data: {},
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        state.qrcode = data.data.qrcode_url;
                        state.channel_id = data.data.channel_id
                        get_inter_login();
                    }
                });
            }
        })

        let get_hot = () => {
            getData({
                url: "api/search/get_hot_search",
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.hot= data.data.hot_search
                }
            });
        }

        let init = () => { 
            get_hot()
            store.commit("setLoginBox", false);

            if (name == "search") {
                state.search = route.query.text;
            }

            state.result =  localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : []
        }
        init ()

        let to_search =  () => {
            if(state.search != '') {
                router.push({
                    path:'/search',
                    query: {
                        text: state.search
                    }
                })
                window.scrollTo(100,0)
                history(state.search)
            }

            if(name == 'search') {
                ctx.emit('get',state.search);
            }
        }

        let focus_search = () => {
            if(state.result.length > 0) {
                state.result_show = true
            }
        }

        let result_del = (v,k) => {
            state.result.splice(k,1)
            localStorage.setItem('search', JSON.stringify(state.result));
        }
 

        let hot_search = (txt) => {
            state.search = txt
            if(state.search != '') {
                router.push({
                    path:'/search',
                    query: {
                        text: state.search
                    }
                })
                window.scrollTo(100,0)
                history(state.search)
            }

            if(name == 'search') {
                ctx.emit('get',state.search);
            }
        }

        let history_go = (txt) => {
            state.result_show = false
            state.search = txt
            if(state.search != '') {
                router.push({
                    path:'/search',
                    query: {
                        text: state.search
                    }
                })
                window.scrollTo(100,0)
            }

            if(name == 'search') {
                ctx.emit('get',state.search);
            }

        }

        let history = (val) => {
            if(state.result) {
                state.result.unshift(val)
            }else {
                state.result.push(val)
            }
            state.result = Array.from(new Set(state.result))
            localStorage.setItem('search', JSON.stringify(state.result));
        }

        // 登录
        let go_login = () => {
            store.commit("setLoginBox", true);
        }

        let logout = () => { 
            store.dispatch('handleLogOut'); 
        }

        let loginClose = () => {
            if(state.inter_login) {
                clearInterval(state.inter_login);
            }
            store.commit("setLoginBox", false);
        }

        // 获取扫码状态
        let get_inter_login = () => {
            state.inter_login = setInterval(() => {
                getData({
                    url: `api/login/check_for_wait_login/${state.channel_id}`,
                    data: {},
                }).then((res) => {
                    let data = res.data;
                    
                    if (data.data.result) {
                        clearInterval(state.inter_login);
                        let info = data.data.result;
                        state.form.openid = info.token;
                        state.form.provider = info.provider;
                        state.form.origin_user_info = info.origin_user_info;
                        state.form.nickname = info.nickname;

                        if (info.verification === false) {
                            get_phone();
                            clearInterval(state.inter_login);
                        } else {
                            clearInterval(state.inter_login);
                            store.dispatch('handleLogin', info)
                            loginClose();
                        }
                    }
                });
            }, 3000);
        }

        // 手机注册页面
        let get_phone = () => {
            state.erweima_show = false;
            state.phone_show = true;
        }

        // 获取验证码
        let get_code = () => {
            if (state.form.phone.trim() === "") return;

            let count = Cookies.get("phoneCountLimit");
            
            if (Number(count) > 3) {
                ElMessage.warning("每天获取验证码次数不能超过三次");
                return false;
            }

            state.timeout = true;

            let nickname = state.form.nickname.trim();
            let phone = state.form.phone;
            let openid = state.form.openid;
            let provider = state.form.provider;

            postData({
                url: "api/login/sendsmspin",
                data: { nickname, phone, openid, provider },
            }).then((res) => {
                let data = res.data;
                // 倒计时
                let num = 60;
                clearInterval(state.time_Interval);
                state.time_Interval = setInterval(() => {
                    state.q_code_msg = `${num}s`;

                    if (num <= 0) {
                        state.q_code_msg = "重新获取验证码";
                        state.timeout = false;
                        clearInterval(state.time_Interval);
                    }
                    num--;
                }, 1000);

                if (data.code === 200) {
                    state.form.key = data.data.key;
                    state.form.register_key = data.data.pin_code;
                    count_limit();
                } else if (data.code === 422) {
                    ElMessage.warning(data.data.phone[0]);
                    state.q_code_msg = "重新获取验证码";
                    state.timeout = false;
                    clearInterval(state.time_Interval);
                }
            });
        }

        // 提交 手机号注册
        let submitForm = () => {
            form_login.value.validate((valid) => {
                if (valid) {
                    postData({
                        url: "api/login/register",
                        data:{
                            nickname: state.form.nickname.trim(),
                            phone: state.form.phone,
                            code: state.form.q_code,
                            key: state.form.key,
                            register_key: state.form.register_key,
                        },
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.message);
                            store.dispatch('handleLogin', data.data)
                            loginClose();
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        } else {
                            ElMessage.error(data.data.msg);

                        }
                    });
                    } else {
                    return false;
                }
            });
        }

        let count_limit = () => {
            let count = Cookies.get("phoneCountLimit");
            if (count !== null && count !== undefined && count !== "") {
                count = Number(count) + 1;
            } else {
                count = 1;
            }

            Cookies.set("phoneCountLimit", count, { expires: 1 });
        }

        let to_home =  () => { 
            router.push({ path: "/" });
            window.scrollTo(100,0)
        }

        let to_user = () => {
            router.push({ path: "/user" });
            if(name == 'user') {
                ctx.emit('get',{temp: "UserIndex", id: 1 });
            }

            window.scrollTo(100,0)

        }

        let to_car = () => {
            router.push({ path: "/car" });

            window.scrollTo(100,0)
        }

        let to_msg = () => {
            router.push({ path: "/user",query: { temp: "UserMsg", id: 7 }, });
            if(name == 'user') {
                ctx.emit('get',{temp: "UserMsg", id: 7 });
            }

            window.scrollTo(100,0)
        }

        let to_ebn = () => {
            window.open(state.ebaina_url,'_blank','')
        }

        let to_privacy = () => {
            window.open('https://www.ebaina.com/privacy','_blank','')
        }

        let to_agreement = () => {
            // window.open('https://www.ebaina.com/agreement','_blank','')
            store.commit("setLoginBox", false);
            router.push({
                path: '/help',
                query:{
                    id: 5
                }
            })

            if(name == 'help') {
                ctx.emit('help',{id: 5});
            }

            window.scrollTo(100,0)
        }

        let login_qq = () => {
            var awidth = 650;
            var aheight = 450;
            var atop = (screen.availHeight - aheight) / 2; //窗口顶部位置,一般不需要改
            var aleft = (screen.availWidth - awidth) / 2; //窗口放中央,一般不需要改
            var param0 = "scrollbars=0,status=0,menubar=0,resizable=2,location=0"; //新窗口的参数 //新窗口的左部位置，顶部位置，宽度，高度
            var params = "top=" + atop + ",left=" + aleft + ",width=" + awidth + ",height=" + aheight + "," + param0;

            state.qqwindow = window.open("https://chip.api.ebaina.com/api/login/qqlogin", "TencentLogin", params);

        
        }

        onMounted(()=>{
            nextTick(()=>{
                document.addEventListener("click", function (e) {
                    if (e.target.className !== 'el-input__inner' && e.target.className !=='result') {
                        state.result_show = false;
                    }
                });
            })

        })

        
        
        return {
            ...toRefs(state),
            show,
            avatar,
            userName,
            loginClose,
            to_home,
            go_login,
            logout,
            count_limit,
            form_login,
            get_code,
            submitForm,
            to_user,
            to_car,
            to_msg,
            to_search,
            name,
            to_ebn,
            to_privacy,
            to_agreement,
            login_qq,
            hot_search,
            msg_count,
            car_count,
            history,
            history_go,
            focus_search,
            result_del

        }
    }
})
</script>

<style lang="less" scoped>
    .nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        height: 154px;
        width: 100%;
        background: #f8f8f8;

        /deep/ .el-input .el-input__inner {
            border: none !important;
        }

        .nav_tip {
            height: 40px;
            line-height: 40px;
            background: #0C073C;
            color: #B4B0C6;
            font-size: 13px;

            .tip_to {
                cursor: pointer;
                i {
                    font-size: 12px;
                    margin-right: 10px;
                }

                span {
                    margin-left: 5px;
                }
            }

            .tip_serve {
                span {
                    margin-left: 30px;
                }
            }
        }

        .nav_other {
            z-index: 1001;
            height: 114px;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        

            .nav_wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
            }

            .nav_logo {
                cursor: pointer;
                margin-bottom: 10px;

                .nav_img {
                    height: 38.25px;
                    width: 367.71px;
                    margin-right: 18px;
                }
            }
        }

        .search {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .nav_search {
            position: relative;
            .search_val {
                width: 511px;
            }
            .search_box {
                height: 44px;
                border: 2px solid #1C46B7;
                border-radius: 4px;
            }
            .search_btn {
                width: 92px;
                height: 41px;
                background: #1C46B7;
                border: none;
                font-size: 16px;
                line-height: 40px;
                color: #ffffff;
                padding: 0;
                border-radius: 0;
                margin-right: -1px;
            }
            .result {
                position: absolute;
                top: 50px;
                left: 0;
                width: 514px;
                background: #FFFFFF;
                border: 1px solid #FDFDFD;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
                padding: 17px 23px 9px 18px;
                .result_title {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #1C46B7;
                    border-bottom: 1px solid #E0E0E0;
                    margin-bottom: 10px;
                    padding-bottom: 7px;
                }
                .result_item {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #666666;
                    padding: 5px 1px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    .del {
                        display: none;
                    }
                }
                .result_item:hover {
                    background: #ededed;

                    .del {
                        display: block;
                    }
                }
            }
        }

        .search_word {
            margin-top: 10px;
            font-size: 12px;
            line-height: 17px;
            color: #666666;
            span {
                margin-left: 16px;
                cursor: pointer;
                &:first-child {
                    margin-left: 10px;
                }
            }
            span:hover {
                color: #1c46b7;
            }
        }

        .is_login_box {
            position: relative;
            flex-shrink: 0;
            align-items: center;
            cursor: pointer;
            margin-bottom: 27px;

            .item {
                margin-right: 22px;
                font-size: 12px;
                color: #333;
            }

            .bage {
                position: relative;
            }

            .txt {
              margin-top: -4px;
            }

            .t-icon {
                margin-right: 2px;
                height: 20px;
                width: 20px;
                font-size: 20px;
            }

            .dot {
                position:absolute;
                top: -7px;
                right: -3px;
                background: #f00;
                color:#fff;
                padding: 1px 5px;
                border-radius: 50%;
            }

            img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

            .nickname {
                margin-left: 4px;
                font-size: 12px;
                font-weight: 400;
                color: #333;
                width: 40px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .logout {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #333;
                text-decoration: underline;
                margin-left: 8px;
            }

            .log_main {
                position: absolute;
                top: 62px;
                z-index: 9;
                padding: 10px 0 20px;
                height: 160px;
                width: 128px;
                font-size: 14px;
                font-weight: 400;
                color: #666;
                text-align: center;
                background: #ffffff;
                background: #ffffff;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

                .iconfont {
                color: #1C46B7;
                margin-right: 5px;
                }

                p {
                padding: 14px 0;
                cursor: pointer;
                }

                p:hover {
                color: #1C46B7;
                }
            }
        }

        .logo_txt {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 27px;
            color: #333;
            text-decoration: underline;
            cursor:pointer;
            align-items: center;
            .t-icon {
                height: 25px; 
                width: 25px;
                margin-right: 7px;
            }
        }
    }

</style>
<style lang="less">
  .modal_login {
      height: 580px;
      width: 560px;
      

      .login {
          width: 100%;
          height: 100%;

          .login_box {
              width: 400px;
              padding: 40px 38px;
              border: 1px solid #e3e3e3;

              .title {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 25px;
                  color: #333333;
                  margin-bottom: 52px;
              }

              .qrcode {
                  display: block;
                  margin: 0 auto 16px;
                  height: 180px;
                  width: 180px;
              }

              .tip {
                  text-align: center;
                  font-size: 13px;
                  line-height: 18px;
                  color: #333333;
                  margin-bottom: 25px;
              }

              .divi {
                  color: #999!important;
              }

              .qq {
                  width: 30px;
                  height: 30px;
                  margin: 16px auto 20px;
                  background: #FFFFFF;
                  border: 1px solid #D8D8D8;
                  border-radius: 50%;
                  cursor: pointer;
                  .t-icon {
                      height: 15px;
                      width: 15px;
                  }
              }

              .agree {
                  text-align: center;
                  font-size: 12px;
                  color: #666;
                  span {
                      color: #047dd6;
                      cursor: pointer;
                  }
              }
          }

          .phone_box {
              .title {
                  margin-bottom: 44px;
              }

              .get_q_code {
                  position: absolute;
                  top: 0;
                  right: 40px;
                  font-size: 16px;
                  color: #1b4fa8;
              }

              .submit_btn {
                  width: 300px;
                  height: 46px;
                  color: #fff;
                  background: #1b4fa8;
              }

              .el-form-item {
                  .el-input {
                      width: 300px;
                      height: 46px;
                      border-bottom: 1px solid #eedede;
                      input {
                          padding-left: 0;
                          height: 100% !important;
                      }
                  }
                  /deep/ .el-form-item__content {
                  margin: 0 !important;
                  }
                  /deep/ .el-input .el-input__inner {
                  border: 1px solid #d1d1d1 !important;
                  }
              }
          }
      }
  }
</style>
